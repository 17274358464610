<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
         <button
                    type="button"
                    id="prevBtn"
                    class="backbtn"
                   @click="previousRoute"
                  >
                   <i class="fa fa-arrow-left"></i>
                  </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="fas fa-receipt"></i
                ></span>
                <h1>Create Fee Type</h1>
              </div>
              <div style="margin-top:10px"> <input type="text" v-model="searchWords" placeholder="Search Fee type" /> </div>
              <div class="mt-1">
                
                <button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.crtfeeType>
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Type</span>
                </button>
              </div>
              
            </div>
            <div class="widjetcontent">
            
                <div class="crtfeeplancont" style="padding: 0 13px">
                  <div
                    class="widjethdr dispflex"
                    style="align-items: baseline; padding: 10px 0"
                  >
                    <div style="width: auto">
                      <h1 style="margin: 0">Fee Type</h1>
                    </div>
                    <!-- <div > <input type="text" v-model="searchWords" placeholder="Search Fee type" /></div>
                    <div class="mr-1">
                     
                      <button
                       
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.crtfeeType
                      >
                        <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Type</span>
                      </button>
                    </div> -->
                  </div>
                  <div class="restable" v-if="feesTypeList.length > 0">
                    <div class="resrow resheader">
                      <div class="cell">Fee Type Id</div>
                      <div class="cell">Fee Type Name</div>
                       <div class="cell">Last Modified By</div>
                      <div class="cell">Last Modified Date</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow"
                      v-for="(type, index) in feesTypeList"
                      :key="index"
                    >
                     
                      <div class="cell" data-title="Plan Name">{{ type.feeTypeId }}</div>
                      <div class="cell" data-title="Admin Name">{{ type.name }}</div>
                       <div class="cell" data-title="Department Name">
                        {{ type.updatedBy ? type.updatedBy.firstName + " " +  type.updatedBy.lastName : '' }}
                      </div>
                      <div  class="cell" data-title="Role">
                            {{ type.updatedDate | dateFormat }}
                          </div>
                      
                      <div class="cell" data-title="Action">
                        <button
                          @click.prevent="editFeeType(index)"
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit"></i><span class="tooltiptext">Edit</span>
                        </button>
                        <button class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
                       
                      </div>
                    
                    </div>
                  </div>
                  <div class="restable" v-else>
                    <div class="resrow resheader">
                      <div class="cell">Fee Type Id</div>
                      <div class="cell">Fee Type Name</div>
                       <div class="cell">Last Modified By</div>
                      <div class="cell">Last Modified Date</div>
                      <div class="cell">Action</div>
                    </div>
                    
                    <div class="widjet"  style="height: 235px;width: 339%;border-radius: 0px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;" >No Fee Type is created</span>
          </div>
        </div>
                  </div>
                </div>
                 <div class="text-center mt-4" v-if="feesTypeList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
               <!-- <div class="widjetfooter text-right dk_iconsml mt-3">
                  <button
                    type="button"
                    id="prevBtn"
                    class="btn btnsml"
                   @click="previousRoute"
                  >
                    <i class="fa fa-arrow-left"></i> Back
                  </button>
                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
<div>
     <b-modal size="sm"
      id="crtfeeType"
      style="width: 1% !important"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>{{ editId ? 'Edit Fees Type' : 'Create Fees Type' }}</h2>
        <br />
        <div>
          <form
            data-vv-scope="crtfeeTypeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editId">
                <span class="has-float-label">
                  <input
                    disabled
                    type="text"
                    name="feeTypeId"
                    v-model="feeType.feeTypeId"
                    class="form-control form-input"
                    id="fpnameid"
                  />
                 
                  <label  for="fpnameid"
                    >Fees Type id <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    @input="checkFeeTypeName"
                    type="text"
                    name="fTname"
                    v-model="feeType.name"
                    class="form-control form-input"
                    id="fpnameid"
                  />
                  <label
                    v-if="errors.first('crtfeeTypeValidate.fTname') || !isFeetypeAvailable"
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('crtfeeTypeValidate.fTname') ? 'Fees Type Name is required ' : 'Fees Type Name already exist. please use a different one.' }} </label
                  >
                  <label v-else for="fpnameid"
                    >Fees Type Name <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
          </form>

          <div class=" mt-3 pt-3" >
          
            <div class="text-center dk_iconsml">
              <button
               v-if="editId"
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateFeeType"
              >
                  <span>Update</span>
              </button>
              
              <button
              v-else
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="createFeeType"
              >
                  <span>Save</span>
              </button>
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
     <b-modal id="deleteFeeTypePopup">
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </div>
     <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
   
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";


export default {
  name: "vi-fees-management",
  data() {
    return {
      feeType: {
        feeTypeId: "",
        name: ""
      },
      feesTypeList: [],
      feesTypeListAll: [],
      editId: null,
      isFeetypeAvailable: true,
      preFeeTypeName: '',
      searchWords: '',
      skip: 0, // set the skip info
      limit: 7, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      viLoader: false
    };
  },
  created() {
    this.getFeesTypeList();
    this.getFeesTypeListAll()
  },
  computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                  this.getFeesTypeList();
              }
          }
  },
  methods: {
      previousRoute(){
      let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
    
     this.$router.go(-1)
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getFeesTypeList();
    },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getFeesTypeList();
    },
    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getFeesTypeList();
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    closePoup() {
      this.$bvModal.hide("crtfeeType");
      this.feeType.feeTypeId = "";
      this.feeType.name = '';
      this.editId = null
      
    },
    editFeeType(index){

      const feeTypeDetail = this.feesTypeList[index]
      this.editId = feeTypeDetail._id,
      this.feeType.feeTypeId = feeTypeDetail.feeTypeId;
      this.feeType.name = feeTypeDetail.name;
      this.preFeeTypeName = feeTypeDetail.name;
      
      this.$bvModal.show("crtfeeType");

    },
    showDeleteModal(index){

      const feeTypeDetail = this.feesTypeList[index]
      this.deleteId = feeTypeDetail._id,
     
      this.$bvModal.show("deleteFeeTypePopup");

    },
    closeDeletePopUp(){
      this.$bvModal.hide("deleteFeeTypePopup");
      this.deleteId = null;
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viDelete(`/feesManagement/deleteFeesType/${this.deleteId}`,userData.token);

        if (response.isSuccess) {
          this.closeDeletePopUp();
          this.getFeesTypeList();
          this.getFeesTypeListAll()
          let getCounts = this.$store.getters.getFeesManagementObjCount
          getCounts.feeType = getCounts.feeType - 1

          this.$store.dispatch('setFeesManagementObjCount', getCounts);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },
    async checkFeeTypeName() {
         let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {

        let allowDupUser = true;

        if (this.editId) {
          if ( this.feeType.name == this.preFeeTypeName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
          
          const details = this.feesTypeListAll.find((x) => x.name == this.feeType.name);
           if(details){
              this.isFeetypeAvailable = false
           }else{
              this.isFeetypeAvailable = true
           }

      
        }
        }
    },
     async getFeesTypeListAll() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/feesManagement/getFeesTypeList",
          userData.token
        );
        if (response.isSuccess) {
          this.feesTypeListAll = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.viLoader = false;
      }
    },
    async getFeesTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
         const response = await ViService.viXGet(`/feesManagement/getFeesTypeListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);
       
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);

           this.feesTypeList = resultData.feesTypeList
           //resultData.feesTypeList

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async createFeeType() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isFeetypeAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPost("/feesManagement/createFeeType",this.feeType,userData.token );

          if (response.isSuccess) {
            this.closePoup();
            this.getFeesTypeList();
            this.getFeesTypeListAll()
            this.$toasted.success(response.message);

            let getCounts = this.$store.getters.getFeesManagementObjCount
            getCounts.feeType = getCounts.feeType + 1

            this.$store.dispatch('setFeesManagementObjCount', getCounts);

          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      } else {
        this.$toasted.error(message);

      }
    },
     async updateFeeType() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(`/feesManagement/updateFeeType/${this.editId}`,this.feeType,userData.token );

          if (response.isSuccess) {
            this.closePoup();
            this.getFeesTypeList();
            this.getFeesTypeListAll()
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      } else {
        this.$toasted.error(message);
      }
    },
  },
  components: {
    ViSpinner

  },
};
</script>